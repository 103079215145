import React from 'react';
import styled from '@emotion/styled';
import Box from 'ui-box';

const Container = styled(Box)`
  max-width: 2000px;
  margin: 0 auto;
  position: relative;
`;

export const PageWrapper = ({children}) => {
  return <Container>{children}</Container>;
};
