const bg = '#0A0A0A';
const button = 'linear-gradient(270.02deg, #FDB913 0.02%, #F2994A 99.98%)';
const primary = '#FDB913';
const border = '#272729';
const gray = '#151414';

export const colors = {
  bg,
  button,
  primary,
  border,
  gray,
};
