export const font = {
    size: {
        largest: '7.0rem',
        larger: '5.0rem',
        large: '3.0rem',
        big: '2.3rem',
        medium: '1.55rem',
        normal: '1.6rem',
        small: '1.4rem',
        tiny: '1.2rem',
        tiniest: '1.0rem',
        inherit: 'inherit',
      },
    weight: {
        normal: 400,
        bold: 600,
        bolder: 800
    }
}