import {colors} from './colors';
import {font} from './font';
import {mq, breakpoints} from './mediaQueries';

export const theme = {
  colors,
  font,
  mq,
  breakpoints,
};
