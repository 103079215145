import {PageWrapper} from 'components/organisms/PageWrapper';
import React, {Suspense, lazy} from 'react';
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';

const Home = lazy(() => import('./components/pages/Home/Home'));

const RouterComponent = () => {
  return (
    <PageWrapper>
      <Router>
        <Suspense fallback={<div />}>
          <Routes>
            <Route exact path='/' element={<Home />} />
          </Routes>
        </Suspense>
      </Router>
    </PageWrapper>
  );
};

export default RouterComponent;
